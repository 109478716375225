import { LANG_TYPES, TEXT_TYPES } from '../constants/languages';

export enum SOURCE_TYPES {
  SURVEY_TYPE = 'sentiment',
  SUPPORT_TYPE = 'volume',
  NPS_TYPE = 'nps',
  CSAT_TYPE = 'csat',
  CPO_TYPE = 'cpo',
  CONVERSATIONS_PER_ORDER = 'CPO',
  PERCENT_TICKET_TYPE = 'percent_of_tickets',
  VOICE_TYPE = 'VOI',
  FCR = 'fcr',
  OVERALL_DISSATISFACTION = 'overall_dissatisfied',
  REPLY_TIME_BUSINESS = 'reply_time_in_minutes_business',
  REPLY_TIME_CALENDAR = 'reply_time_in_minutes_calendar',
  FULL_RESOLUTION_TIME_BUSINESS = 'full_resolution_time_in_minutes_business',
  FULL_RESOLUTION_TIME_CALENDAR = 'full_resolution_time_in_minutes_calendar',
  FIRST_RESOLUTION_TIME_BUSINESS = 'first_resolution_time_in_minutes_business',
  FIRST_RESOLUTION_TIME_CALENDAR = 'first_resolution_time_in_minutes_calendar',
  REQUESTER_WAIT_TIME_BUSINESS = 'requester_wait_time_in_minutes_business',
  REQUESTER_WAIT_TIME_CALENDAR = 'requester_wait_time_in_minutes_calendar',
  MEDIAN_TIME_TO_REPLY = 'median_time_to_reply',
  TIME_TO_FIRST_CLOSE = 'time_to_first_close',
  TIME_TO_LAST_CLOSE = 'time_to_last_close',
  COUNT_CONVERSATION_PARTS = 'count_conversation_parts',
  REPLIES = 'replies',
  UNKNOWN_SURVEY_SUPPORT = 'SUR_SUP',
  SURVEY_CSAT_TYPE = 'survey-csat',
  PERCENT_OF_SEGMENTS = 'percent_of_tickets_for_filters',
  PERCENT_OF_RESPONSES = 'percentage_of_responses',
  CSAT_RATING = 'csat_rating',
  AVERAGE_HANDLE_TIME = 'average_handle_time',
  CUSTOMER_INTERACTION_TIME = 'customer_interaction_time',
  PERCENT_NEGATIVE_SENTIMENT = 'percent_negative_sentiment',
  AVERAGE_SENTIMENT = 'average_sentiment',
  PERCENT_CALLS_RESOLVED = 'percentage_calls_resolved',
  PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL = 'percentage_customer_happy_at_end_of_call',
  PERCENT_EMPATHETIC_AGENT = 'percentage_empathetic_agent',
  PERCENT_PROFESSIONAL_AGENT = 'percentage_professional_agent',
  PERCENT_POLITE_AGENT = 'percentage_polite_agent',
  ORDERS = 'orders',
  PERCENT_PROFESSIONAL_AGENT_VALUE = 'percentage_professional_agent_value',
  PERCENT_POLITE_AGENT_VALUE = 'percentage_polite_agent_value',
  PERCENT_EMPATHETIC_AGENT_VALUE = 'percentage_empathetic_agent_value',
  PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL_ANALYSIS = 'percentage_customer_happy_at_end_of_call_analysis',
  PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL = 'percentage_positive_sentiment_include_neutral',
  PERCENT_NEGATIVE_SENTIMENT_INCLUDE_NEUTRAL = 'percentage_negative_sentiment_include_neutral',
  CSAT_EXPERIENCE = 'csat_experience',
  PERCENT_CONVERSATION_RESOLVED = 'percentage_conversations_resolved',
  PERCENT_EMPATHY_SHOWN_BY_AGENT = 'percentage_empathy_shown_by_agent',
  PERCENT_FRIENDLINESS_SHOWN_BY_AGENT = 'percentage_friendliness_shown_by_agent',
  PERCENT_PROFESSIONALISM_SHOWN_BY_AGENT = 'percentage_professionalism_shown_by_agent',
  PERCENT_AGENT_PERFORMANCE_QUALITY = 'percentage_agent_performance_quality',
  FCR_SAME_CALLER = 'fcr_for_same_caller',
  FCR_SAME_CALLER_SAME_REASON = 'fcr_for_same_caller_same_reason',
  ORIGINAL_CALLS = 'original_calls',
}
export enum ADMIN_INDEX_SOURCE_TYPES {
  sentiment = 'SUR',
  volume = 'SUP',
  nps = 'NPS',
  csat = 'CST',
  cpo = 'SUP_CPO',
  percent_of_tickets = 'PERCENT_TICKET_TYPE',
  VOI = 'VOI',
  fcr = 'fcr',
  median_time_to_reply = 'median_time_to_reply',
  time_to_first_close = 'time_to_first_close',
  time_to_last_close = 'time_to_last_close',
  count_conversation_parts = 'count_conversation_parts',
  overall_dissatisfied = 'overall_dissatisfied',
  reply_time_in_minutes_business = 'reply_time_in_minutes_business',
  SUR_SUP = 'SUR_SUP',
  fcr_for_same_caller = 'fcr_for_same_caller',
  fcr_for_same_caller_same_reason = 'fcr_for_same_caller_same_reason',
  original_calls = 'original_calls',
}

const SOURCE_TYPE_API_VALUES = Object.freeze({
  [SOURCE_TYPES.SURVEY_TYPE]: 'sentiment',
  [SOURCE_TYPES.SUPPORT_TYPE]: 'volume',
  [SOURCE_TYPES.NPS_TYPE]: 'nps',
  [SOURCE_TYPES.CSAT_TYPE]: 'csat',
  [SOURCE_TYPES.CPO_TYPE]: 'cpo',
  [SOURCE_TYPES.PERCENT_TICKET_TYPE]: 'percent_of_tickets',
  [SOURCE_TYPES.VOICE_TYPE]: 'VOI',
  [SOURCE_TYPES.FCR]: 'fcr',
  [SOURCE_TYPES.OVERALL_DISSATISFACTION]: 'overall_dissatisfied',
  [SOURCE_TYPES.REPLY_TIME_BUSINESS]: SOURCE_TYPES.REPLY_TIME_BUSINESS,
  [SOURCE_TYPES.UNKNOWN_SURVEY_SUPPORT]: 'SUR_SUP',
  [SOURCE_TYPES.MEDIAN_TIME_TO_REPLY]: 'median_time_to_reply',
  [SOURCE_TYPES.TIME_TO_FIRST_CLOSE]: 'time_to_first_close',
  [SOURCE_TYPES.TIME_TO_LAST_CLOSE]: 'time_to_last_close',
  [SOURCE_TYPES.COUNT_CONVERSATION_PARTS]: 'count_conversation_parts',
  [SOURCE_TYPES.ORDERS]: 'orders',
  [SOURCE_TYPES.SURVEY_CSAT_TYPE]: 'survey-csat',
  [SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL]: 'percentage_positive_sentiment_include_neutral',
  [SOURCE_TYPES.CSAT_EXPERIENCE]: 'csat_experience',
  [SOURCE_TYPES.FCR_SAME_CALLER]: 'fcr_for_same_caller',
  [SOURCE_TYPES.FCR_SAME_CALLER_SAME_REASON]: 'fcr_for_same_caller_same_reason',
  [SOURCE_TYPES.ORIGINAL_CALLS]: 'original_calls',
});

const SourceModelTypesEnum = Object.freeze({
  [SOURCE_TYPES.SURVEY_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'SUR',
      [TEXT_TYPES.MID]: 'Survey',
      [TEXT_TYPES.LONG]: 'Survey',
      [TEXT_TYPES.ALIAS]: 'Sentiment',
      [TEXT_TYPES.ALIAS_1]: 'Sentiment Score',
      [TEXT_TYPES.ALIAS_2]: 'Current Sentiment Score',
      [TEXT_TYPES.ALIAS_3]: 'Performance Drivers',
      [TEXT_TYPES.ALIAS_4]: 'Positive Sentiment Drivers',
      [TEXT_TYPES.ALIAS_5]: 'Negative Sentiment Drivers',
    },
  },
  [SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Sentiment',
    },
  },
  [SOURCE_TYPES.SUPPORT_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'SUP',
      [TEXT_TYPES.MID]: 'Support',
      [TEXT_TYPES.LONG]: 'No. Of Tickets',
      [TEXT_TYPES.ALIAS]: 'Volume',
      [TEXT_TYPES.ALIAS_1]: 'Volume Count',
      [TEXT_TYPES.ALIAS_2]: 'Current Contact Volume',
      [TEXT_TYPES.ALIAS_3]: 'Top Reasons for Contact',
      [TEXT_TYPES.ALIAS_4]: 'Biggest Decreases in Issues',
      [TEXT_TYPES.ALIAS_5]: 'Biggest Increases in Issues',
    },
  },
  [SOURCE_TYPES.NPS_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'NPS',
      [TEXT_TYPES.MID]: 'NPS',
      [TEXT_TYPES.LONG]: 'Net Promoter Score',
      [TEXT_TYPES.ALIAS]: 'NPS',
      [TEXT_TYPES.ALIAS_1]: 'NPS Score',
    },
  },
  [SOURCE_TYPES.CPO_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'CPO',
      [TEXT_TYPES.MID]: 'CPO',
      [TEXT_TYPES.LONG]: 'Contacts per Order',
      [TEXT_TYPES.ALIAS]: 'CPO',
      [TEXT_TYPES.ALIAS_1]: 'CPO score',
      [TEXT_TYPES.ALIAS_2]: 'Overall Contacts per Order',
      [TEXT_TYPES.ALIAS_3]: 'Top Reasons for Contact',
      [TEXT_TYPES.ALIAS_4]: 'Top Decreases',
      [TEXT_TYPES.ALIAS_5]: 'Top Increases',
    },
  },
  [SOURCE_TYPES.CONVERSATIONS_PER_ORDER]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Conversations per Order',
    },
  },
  [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: '% Of Tickets',
      [TEXT_TYPES.MID]: '% Of Tickets',
      [TEXT_TYPES.LONG]: '% Of Tickets',
      [TEXT_TYPES.ALIAS]: '% Of Tickets',
      [TEXT_TYPES.ALIAS_1]: '% Of Tickets',
      [TEXT_TYPES.ALIAS_2]: '% Of Tickets',
      [TEXT_TYPES.ALIAS_3]: 'Top Reasons for Contact',
      [TEXT_TYPES.ALIAS_4]: 'Top Decreases',
      [TEXT_TYPES.ALIAS_5]: 'Top Increases',
    },
  },
  [SOURCE_TYPES.CSAT_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'CSAT',
      [TEXT_TYPES.MID]: 'CSAT',
      [TEXT_TYPES.LONG]: 'CSAT',
      [TEXT_TYPES.ALIAS]: 'CSAT',
      [TEXT_TYPES.ALIAS_1]: 'CSAT Score',
    },
  },
  [SOURCE_TYPES.CSAT_EXPERIENCE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'CSAT',
    },
  },
  [SOURCE_TYPES.FCR_SAME_CALLER]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'FCR For Same Caller',
    },
  },
  [SOURCE_TYPES.FCR_SAME_CALLER_SAME_REASON]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'FCR For Same Caller Same Reason',
    },
  },
  [SOURCE_TYPES.ORIGINAL_CALLS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Original Calls',
    },
  },
  [SOURCE_TYPES.VOICE_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'VOI',
      [TEXT_TYPES.MID]: 'Voice',
      [TEXT_TYPES.LONG]: 'Voice',
      [TEXT_TYPES.ALIAS]: 'Voice',
      [TEXT_TYPES.ALIAS_1]: 'Voice',
    },
  },
  [SOURCE_TYPES.FCR]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'FCR',
      [TEXT_TYPES.MID]: 'FCR',
      [TEXT_TYPES.LONG]: 'FCR',
      [TEXT_TYPES.ALIAS]: 'FCR',
      [TEXT_TYPES.ALIAS_1]: 'FCR',
    },
  },
  [SOURCE_TYPES.OVERALL_DISSATISFACTION]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'Overall Dissatisfaction',
      [TEXT_TYPES.MID]: 'Overall Dissatisfaction',
      [TEXT_TYPES.LONG]: 'Overall Dissatisfaction',
      [TEXT_TYPES.ALIAS]: 'Overall Dissatisfaction',
      [TEXT_TYPES.ALIAS_1]: 'Overall Dissatisfaction',
    },
  },
  [SOURCE_TYPES.REPLY_TIME_BUSINESS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average agent response time (Business)',
    },
  },
  [SOURCE_TYPES.REPLY_TIME_CALENDAR]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average agent response time (Calendar)',
    },
  },
  [SOURCE_TYPES.FULL_RESOLUTION_TIME_BUSINESS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average full resolution time (Business)',
    },
  },
  [SOURCE_TYPES.FULL_RESOLUTION_TIME_CALENDAR]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average full resolution time (Calendar)',
    },
  },
  [SOURCE_TYPES.FIRST_RESOLUTION_TIME_BUSINESS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average first resolution time (Business)',
    },
  },
  [SOURCE_TYPES.FIRST_RESOLUTION_TIME_CALENDAR]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average first resolution time (Calendar)',
    },
  },
  [SOURCE_TYPES.REQUESTER_WAIT_TIME_BUSINESS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average wait time (Business)',
    },
  },
  [SOURCE_TYPES.REQUESTER_WAIT_TIME_CALENDAR]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average wait time (Calendar)',
    },
  },
  [SOURCE_TYPES.REPLIES]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Replies',
    },
  },
  [SOURCE_TYPES.UNKNOWN_SURVEY_SUPPORT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'Null',
      [TEXT_TYPES.MID]: 'Null',
      [TEXT_TYPES.LONG]: 'Not confimed either Survey or Support',
    },
  },
  [SOURCE_TYPES.COUNT_CONVERSATION_PARTS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Replies',
    },
  },
  [SOURCE_TYPES.MEDIAN_TIME_TO_REPLY]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average Wait Time',
    },
  },
  [SOURCE_TYPES.TIME_TO_FIRST_CLOSE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average First Resolution Time',
    },
  },
  [SOURCE_TYPES.TIME_TO_LAST_CLOSE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average Full Resolution Time',
    },
  },
  [SOURCE_TYPES.SURVEY_CSAT_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: 'CSAT',
      [TEXT_TYPES.MID]: 'CSAT',
      [TEXT_TYPES.LONG]: 'CSAT',
      [TEXT_TYPES.ALIAS]: 'CSAT',
      [TEXT_TYPES.ALIAS_1]: 'CSAT Score',
    },
  },
  [SOURCE_TYPES.PERCENT_OF_SEGMENTS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% of Segments',
    },
  },
  [SOURCE_TYPES.PERCENT_OF_RESPONSES]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% of Responses',
    },
  },
  [SOURCE_TYPES.CSAT_RATING]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average CSAT',
    },
  },
  [SOURCE_TYPES.AVERAGE_HANDLE_TIME]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Avg Handle Time',
    },
  },
  [SOURCE_TYPES.CUSTOMER_INTERACTION_TIME]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Customer Interaction Time',
    },
  },
  [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% of Negative Sentiment',
    },
  },
  [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% of Negative Sentiment',
    },
  },
  [SOURCE_TYPES.AVERAGE_SENTIMENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'Average Sentiment',
    },
  },
  [SOURCE_TYPES.PERCENT_CALLS_RESOLVED]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Calls Resolved',
    },
  },
  [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Customers Happy at End of Call',
    },
  },
  [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL_ANALYSIS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Customers Happy at End of Call',
    },
  },
  [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Empathy Shown',
    },
  },
  [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT_VALUE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Empathy Shown',
    },
  },
  [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Professionalism Shown',
    },
  },
  [SOURCE_TYPES.PERCENT_CONVERSATION_RESOLVED]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Conversation Resolved',
    },
  },
  [SOURCE_TYPES.PERCENT_EMPATHY_SHOWN_BY_AGENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Empathy in Agents',
    },
  },
  [SOURCE_TYPES.PERCENT_FRIENDLINESS_SHOWN_BY_AGENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Friendliness Shown by Agent',
    },
  },
  [SOURCE_TYPES.PERCENT_PROFESSIONALISM_SHOWN_BY_AGENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Professionalism shown by agent',
    },
  },
  [SOURCE_TYPES.PERCENT_AGENT_PERFORMANCE_QUALITY]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Agent Performance',
    },
  },
  [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT_VALUE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Professionalism Shown',
    },
  },
  [SOURCE_TYPES.PERCENT_POLITE_AGENT]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Politeness Shown',
    },
  },
  [SOURCE_TYPES.PERCENT_POLITE_AGENT_VALUE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: '% Politeness Shown',
    },
  },
  [SOURCE_TYPES.ORDERS]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.ALIAS]: 'orders',
    },
  },
} as const);

export const ADMIN_INDEX_SURVEY_TYPES = [
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.SURVEY_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.NPS_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.CSAT_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.FCR],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.CSAT_EXPERIENCE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.FCR_SAME_CALLER],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.FCR_SAME_CALLER_SAME_REASON],
];

export const SURVEY_TYPES = [
  SOURCE_TYPES.SURVEY_TYPE,
  SOURCE_TYPES.NPS_TYPE,
  SOURCE_TYPES.CSAT_TYPE,
  SOURCE_TYPES.FCR,
  SOURCE_TYPES.CSAT_RATING,
  SOURCE_TYPES.AVERAGE_SENTIMENT,
  SOURCE_TYPES.PERCENT_CALLS_RESOLVED,
  SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL,
  SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL_ANALYSIS,
  SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT,
  SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT_VALUE,
  SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT,
  SOURCE_TYPES.PERCENT_CONVERSATION_RESOLVED,
  SOURCE_TYPES.PERCENT_EMPATHY_SHOWN_BY_AGENT,
  SOURCE_TYPES.PERCENT_FRIENDLINESS_SHOWN_BY_AGENT,
  SOURCE_TYPES.PERCENT_PROFESSIONALISM_SHOWN_BY_AGENT,
  SOURCE_TYPES.PERCENT_AGENT_PERFORMANCE_QUALITY,
  SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT_VALUE,
  SOURCE_TYPES.PERCENT_POLITE_AGENT,
  SOURCE_TYPES.PERCENT_POLITE_AGENT_VALUE,
  SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL,
  SOURCE_TYPES.CSAT_EXPERIENCE,
  ...ADMIN_INDEX_SURVEY_TYPES,
] as const;

export const ADMIN_INDEX_SUPPORT_TYPES = [
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.SUPPORT_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.VOICE_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.CPO_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.PERCENT_TICKET_TYPE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.OVERALL_DISSATISFACTION],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.MEDIAN_TIME_TO_REPLY],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.TIME_TO_FIRST_CLOSE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.TIME_TO_LAST_CLOSE],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.COUNT_CONVERSATION_PARTS],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.CONVERSATIONS_PER_ORDER],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.ORDERS],
  ADMIN_INDEX_SOURCE_TYPES[SOURCE_TYPES.ORIGINAL_CALLS],
];

export const TIME_TYPES = [
  SOURCE_TYPES.REPLY_TIME_BUSINESS,
  SOURCE_TYPES.REPLY_TIME_CALENDAR,
  SOURCE_TYPES.FULL_RESOLUTION_TIME_BUSINESS,
  SOURCE_TYPES.FULL_RESOLUTION_TIME_CALENDAR,
  SOURCE_TYPES.FIRST_RESOLUTION_TIME_BUSINESS,
  SOURCE_TYPES.FIRST_RESOLUTION_TIME_CALENDAR,
  SOURCE_TYPES.REQUESTER_WAIT_TIME_BUSINESS,
  SOURCE_TYPES.REQUESTER_WAIT_TIME_CALENDAR,
  SOURCE_TYPES.MEDIAN_TIME_TO_REPLY,
  SOURCE_TYPES.TIME_TO_FIRST_CLOSE,
  SOURCE_TYPES.TIME_TO_LAST_CLOSE,
  SOURCE_TYPES.AVERAGE_HANDLE_TIME,
  SOURCE_TYPES.CUSTOMER_INTERACTION_TIME,
];

export const SUPPORT_TYPES = [
  SOURCE_TYPES.SUPPORT_TYPE,
  SOURCE_TYPES.VOICE_TYPE,
  SOURCE_TYPES.CPO_TYPE,
  SOURCE_TYPES.PERCENT_TICKET_TYPE,
  SOURCE_TYPES.OVERALL_DISSATISFACTION,
  SOURCE_TYPES.COUNT_CONVERSATION_PARTS,
  SOURCE_TYPES.MEDIAN_TIME_TO_REPLY,
  SOURCE_TYPES.TIME_TO_FIRST_CLOSE,
  SOURCE_TYPES.TIME_TO_LAST_CLOSE,
  SOURCE_TYPES.ORDERS,
  ...TIME_TYPES,
  ...ADMIN_INDEX_SUPPORT_TYPES,
];

export const TYPE_API_METRIC_MAPPING = {
  [SOURCE_TYPES.CSAT_TYPE]: SOURCE_TYPES.SURVEY_CSAT_TYPE,
} as const;

export { SOURCE_TYPE_API_VALUES, SourceModelTypesEnum };
