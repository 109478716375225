import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';
import { getDateRangeBounds, getFiltersData } from 'src/api/Filters';
import { Dimension, Metric, ISelectedClientSourceData } from 'src/types/User';
import { DIMENSION_TYPES, SENTISUM_TAG_DIMENSION_OBJECT } from 'Enums/chartDimensionsType';
import { SENTIMENT_METRIC_OBJECT, VOLUME_METRIC_OBJECT, PERCENT_TICKET_METRIC_OBJECT } from 'Enums/ChartMetricObjects';
import { ADMIN_INDEX_SOURCE_TYPES, SOURCE_TYPES, SURVEY_TYPES } from 'Enums/SourceModelTypes';
import { getSourceOptions, setDefaultDateRange } from './filter';
import { EditGraphReportState } from './types/editGraphReport';
import { IDateFilterData, IGetAvailableFiltersResponse, IGetDateRangeBoundsRequest, IGetDateRangeBoundsResponse } from './types/filter';
import { CommonFilterState } from './types/common';
import { IChartComparator } from 'src/types/Filter';
import { getFilterRequestData, getGraphReportData, hasInValidFilters, transformFilters, transformViewToFilters } from 'src/api/Tranformers';
import { IDataSourceFilters, IFilterRelation } from 'src/types/Bookmark';
import { setPreveiousDateRangeOnDateRangeChange } from 'Utils/constants/previousDateRangeIntervals';
import filterColors from 'Constants/filterColors.json';
import { RootState } from '../store';
import { createReport, editReport } from 'src/api/dashboard/Report';
import { getSummaryOverviewGraphData, getSummaryDrivers } from 'src/api/Summary';
import { IGetSummaryGraphDataResponse, IFetchSummaryDriversResponse } from './types/summary';
import { INTERVAL_BREAKDOWN_TYPES } from 'Utils/enums/chartIntervalBreakdownTypes';
import { setBreakDownOnDateRangeChange } from 'Utils/constants/BreakDownOptions';
import { SelectedVisualizationType, SummaryTableVisualization } from 'src/types/enums';
import { setDateRangeOnDateFieldChange } from 'Utils/constants/DateRangeIntervals';
import { setDefaultSourceAction } from './actions/launchdarkly';
import { getReportConfigurationThunk } from './editReport';

export const updateReportDateRangeBounds = createAsyncThunk<IGetDateRangeBoundsResponse, IGetDateRangeBoundsRequest>(
  'updateReportDateRangeBounds',
  ({ source }) => getDateRangeBounds(source)
);
export const getReportAvailableFilters = createAsyncThunk<IGetAvailableFiltersResponse, void, { state: RootState }>(
  'getReportAvailableFilters',
  (_, { getState }) => {
    const { filterdata, sourceType } = getFilterRequestData(getState().editGraphReport);
    return getFiltersData(filterdata, sourceType);
  }
);

export const fetchReportOverviewThunk = createAsyncThunk<IGetSummaryGraphDataResponse, void, { state: RootState }>(
  'fetchReportOverview',
  (_, { getState }) => getSummaryOverviewGraphData({}, getState().editGraphReport)
);

export const fetchReportDriversThunk = createAsyncThunk<IFetchSummaryDriversResponse, void, { state: RootState }>(
  'fetchReportDrivers',
  (_, { getState }) => getSummaryDrivers(getState().editGraphReport)
);

export const createGraphReportThunk = createAsyncThunk('createReportThunk', (dashboardId: number) => createReport(getGraphReportData(dashboardId)));
export const editGraphReportThunk = createAsyncThunk<unknown, number, { state: RootState }>('editReportThunk', (dashboardId, { getState }) =>
  editReport(getState().editReport.id, getGraphReportData(dashboardId))
);

const addDefaultsForSelectedSource = (source: ISelectedClientSourceData, state: CommonFilterState) => {
  if (!source?.es_alias?.name) return;
  state.filters = { source: source.es_alias.name, metadata: [] };
  const newSource = cloneDeep(source);
  if (!newSource.es_alias.dimensions.length) newSource.es_alias.dimensions = [SENTISUM_TAG_DIMENSION_OBJECT];
  if (!newSource.es_alias?.metrics?.length)
    newSource.es_alias.metrics = SURVEY_TYPES.includes(newSource.es_alias.type)
      ? [SENTIMENT_METRIC_OBJECT]
      : [VOLUME_METRIC_OBJECT, PERCENT_TICKET_METRIC_OBJECT];
  state.selectedSourceClientData = newSource;
  state.chartDimensionObject = SENTISUM_TAG_DIMENSION_OBJECT;
  state.chartDimension = DIMENSION_TYPES.SENTISUM_TAG;
  state.chartMetricObject = SURVEY_TYPES.includes(newSource.es_alias.type) ? SENTIMENT_METRIC_OBJECT : VOLUME_METRIC_OBJECT;
  state.chartMetric = SURVEY_TYPES.includes(newSource.es_alias.type) ? SOURCE_TYPES.SURVEY_TYPE : SOURCE_TYPES.SUPPORT_TYPE;
};

const initialState: EditGraphReportState = {
  fetchAvailableFiltersStatus: 'idle',
  fetchAvailbleDateRangeBoundsStatus: 'idle',
  bookmarkFilters: { previousFilterRelation: 'and', datasourceFilters: {} },
  filterdata: { filters: [], topics: [], themes: [] },
  chartDimension: DIMENSION_TYPES.SENTISUM_TAG,
  chartDimensionObject: SENTISUM_TAG_DIMENSION_OBJECT,
  chartMetric: SOURCE_TYPES.SUPPORT_TYPE,
  chartMetricObject: VOLUME_METRIC_OBJECT,
  chartComparator: 'percent',
  chartIntervalBreakdown: 'day',
  visualizationType: 'summary',
  selectedVisualizationType: 'T',
  fetchReportOverviewStatus: 'idle',
  fetchReportDriversStatus: 'idle',
  driverItems: {
    show: true,
    driverCount: 5,
  },
  showBarGraphWarning: false,
};

const EditGraphReportSlice = createSlice({
  name: 'editGraphReport',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetEditFilterReduxState: (state) => {
      const newSource = cloneDeep(state.selectedSourceClientData);
      if (!newSource?.es_alias?.name) return;
      state.filters = { source: newSource.es_alias.name, metadata: [] };
      Object.assign(state, { ...initialState });
      state.selectedSourceClientData = newSource;
      state.chartDimensionObject = SENTISUM_TAG_DIMENSION_OBJECT;
      state.chartDimension = DIMENSION_TYPES.SENTISUM_TAG;
      state.chartMetricObject = SURVEY_TYPES.includes(newSource.es_alias.type) ? SENTIMENT_METRIC_OBJECT : VOLUME_METRIC_OBJECT;
      state.chartMetric = SURVEY_TYPES.includes(newSource.es_alias.type) ? SOURCE_TYPES.SURVEY_TYPE : SOURCE_TYPES.SUPPORT_TYPE;
      state.tableVisualization = SURVEY_TYPES.includes(state.selectedSourceClientData?.es_alias.type)
        ? SummaryTableVisualization.BiggestChanges
        : SummaryTableVisualization.Highest;
    },
    updateEditReportRestrictDimension: (state, action: PayloadAction<boolean>) => {
      state.filters.isRestrictDimension = action.payload;
    },
    updateReportSelectedSource: (state, action: PayloadAction<ISelectedClientSourceData>) => {
      addDefaultsForSelectedSource(action.payload, state);
      if (!state.selectedSourceClientData?.es_alias?.name) return;
      state.tableVisualization = SURVEY_TYPES.includes(state.selectedSourceClientData?.es_alias.type)
        ? SummaryTableVisualization.BiggestChanges
        : SummaryTableVisualization.Highest;
    },
    updateChartDimension: (state, action: PayloadAction<Dimension>) => {
      state.chartDimensionObject = action.payload;
      state.chartDimension = action.payload.name;
      state.filters.isRestrictDimension = false;
    },
    updatechartMetric: (state, action: PayloadAction<Metric>) => {
      state.chartMetricObject = action.payload;
      state.chartMetric = action.payload.name;
    },
    updateChartComparator: (state, action: PayloadAction<IChartComparator>) => {
      state.chartComparator = action.payload;
    },
    updateDataSourceFilters: (state, action: PayloadAction<IDataSourceFilters>) => {
      state.bookmarkFilters.datasourceFilters = action.payload;
      if (state.filters.metadata.length === 0) state.filters.isRestrictDimension = false;
      const newMedataFilters = transformFilters(cloneDeep(state.bookmarkFilters));
      if (!hasInValidFilters(state.bookmarkFilters) && !isEqual(state.filters.metadata, newMedataFilters)) state.filters.metadata = newMedataFilters;
    },
    updatePreviousFilterRelation: (state, action: PayloadAction<IFilterRelation>) => {
      state.bookmarkFilters.previousFilterRelation = action.payload;
      const newMedataFilters = transformFilters(cloneDeep(state.bookmarkFilters));
      if (!isEqual(state.filters.metadata, newMedataFilters)) state.filters.metadata = newMedataFilters;
    },
    updateFilterDateRange: (state, action: PayloadAction<IDateFilterData>) => {
      if (!state.filters) return;
      state.filters.dateRange = { start: action.payload.startDate, end: action.payload.endDate };
      state.filters.interval = action.payload.interval;
      state.filters.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.filters);
      state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.filters.dateRange, state.chartIntervalBreakdown);
    },
    updateChartPreviousInterval: (state, action: PayloadAction<IDateFilterData>) => {
      const { startDate, endDate, interval } = action.payload;
      if (!state.filters) return;
      state.filters.previousDateRange = { start: startDate, end: endDate };
      state.filters.previous_interval = interval;
    },
    updateSelectedDateField: (state, action: PayloadAction<string>) => {
      state.filters.selectedDateField = action.payload;
      state.filters.dateRange = setDateRangeOnDateFieldChange(state.dateRangeBounds, state.filters);
      state.filters.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.filters);
    },
    updateVisualizationType: (state, action: PayloadAction<EditGraphReportState['visualizationType']>) => {
      state.visualizationType = action.payload;
      state.selectedVisualizationType = action.payload === 'summary' ? 'T' : 'LG';
    },
    updateChartIntervalBreakdown: (state, action: PayloadAction<INTERVAL_BREAKDOWN_TYPES>) => {
      state.chartIntervalBreakdown = action.payload;
    },
    updateTableVisualization: (state, action: PayloadAction<SummaryTableVisualization>) => {
      state.tableVisualization = action.payload;
    },
    updateSelectedVisualizationType: (state, action: PayloadAction<SelectedVisualizationType>) => {
      state.selectedVisualizationType = action.payload;
    },
    updateDriverNumber: (state, action: PayloadAction<number>) => {
      state.driverItems.driverCount = action.payload;
    },
    updateDriverShow: (state, action: PayloadAction<boolean>) => {
      state.driverItems.show = action.payload;
      if (action.payload === true) state.driverItems.driverCount = 5;
    },
    updateShowBarGraphWarning: (state, action: PayloadAction<boolean>) => {
      state.showBarGraphWarning = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateReportDateRangeBounds.pending, (state, action) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'pending';
        state.fetchAvailbleDateRangeBoundsError = '';
      })
      .addCase(updateReportDateRangeBounds.fulfilled, (state, action) => {
        const dateRangeData = action.payload.filterdata;
        if (dateRangeData.length && state.filters) {
          state.dateRangeBounds = dateRangeData.map(({ dateRange, dateField }) => ({ ...dateField, ...dateRange }));
          setDefaultDateRange(state, action.meta.arg.defaultDateRangeInterval);
        }
        state.fetchAvailbleDateRangeBoundsStatus = 'fulfilled';
      })
      .addCase(updateReportDateRangeBounds.rejected, (state, action) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'rejected';
        state.fetchAvailbleDateRangeBoundsError = action.error.message;
      })
      .addCase(setDefaultSourceAction, (state, action) => {
        const { user, defaultSource } = action.payload;
        const availablesources = getSourceOptions(user);
        if (availablesources.length) {
          const source =
            availablesources.find(({ name, types }) => (defaultSource ? name === defaultSource : types.includes(ADMIN_INDEX_SOURCE_TYPES.volume)))
              ?.name ?? availablesources[0].name;
          let selectedClientSourceData: ISelectedClientSourceData;
          user?.clients?.forEach(({ es_aliases, ...sourceData }) => {
            if (es_aliases?.some(({ name }) => name === source))
              selectedClientSourceData = { ...sourceData, es_alias: es_aliases.find(({ name }) => name === source) };
          });
          addDefaultsForSelectedSource(selectedClientSourceData, state);
          state.tableVisualization = SURVEY_TYPES.includes(state.selectedSourceClientData?.es_alias.type)
            ? SummaryTableVisualization.BiggestChanges
            : SummaryTableVisualization.Highest;
        }
      })
      .addCase(getReportAvailableFilters.pending, (state) => {
        state.fetchAvailableFiltersStatus = 'pending';
      })
      .addCase(getReportAvailableFilters.fulfilled, (state, action) => {
        const { filters, topics, themes } = action.payload;
        state.filterdata.filters = filters.map((filter, index) => ({ ...filter, theme: filterColors[index] }));
        state.filterdata.topics = topics.map((topic) => ({ value: topic, count: 0 }));
        state.filterdata.themes = themes;
        state.fetchAvailableFiltersStatus = 'fulfilled';
        state.typeMetric = action.payload.typeMetric;
      })
      .addCase(getReportAvailableFilters.rejected, (state) => {
        state.filterdata = { filters: [], topics: [], themes: [] };
        state.fetchAvailableFiltersStatus = 'rejected';
      })
      .addCase(getReportConfigurationThunk.fulfilled, (state, action) => {
        Object.assign(state, initialState);
        const report_type = action.payload.configuration.report_type;
        if (report_type === 'DOC' || report_type === 'MC' || !('selectedClientSourceData' in action.payload)) return;
        const { selectedClientSourceData, configuration, name } = action.payload;
        const { dimension, metrics, source, conditions, dimension_limit, visualization_type, comparisons } = configuration;
        state.selectedSourceClientData = selectedClientSourceData;
        state.chartDimensionObject = dimension;
        state.chartDimension = dimension.name;
        state.chartMetricObject = metrics[0];
        state.chartMetric = metrics[0].name;
        state.visualizationType = report_type === 'LG' ? 'overTime' : 'summary';
        state.selectedVisualizationType = visualization_type ? visualization_type : report_type === 'LG' ? 'LG' : 'T';
        state.driverItems = { show: dimension_limit ? true : false, driverCount: dimension_limit };
        state.filters = { source: source, metadata: conditions.dataSourceFilters ?? [] };
        state.filters.isRestrictDimension = conditions.restrict_dimensions;
        state.bookmarkFilters = transformViewToFilters({ dataSourceFilters: conditions.dataSourceFilters ?? [], search: '' });
        state.chartComparator = comparisons[0] as IChartComparator;
        state.tableVisualization =
          report_type === 'LG'
            ? SURVEY_TYPES.includes(selectedClientSourceData?.es_alias.type) && metrics[0].name === 'sentiment'
              ? SummaryTableVisualization.BiggestChanges
              : SummaryTableVisualization.Highest
            : report_type;
      })
      .addCase(fetchReportOverviewThunk.pending, (state) => {
        state.fetchReportOverviewStatus = 'pending';
      })
      .addCase(fetchReportOverviewThunk.fulfilled, (state, action) => {
        state.reportOverview = action.payload.data[state.chartMetric];
        state.fetchReportOverviewStatus = 'fulfilled';
      })
      .addCase(fetchReportOverviewThunk.rejected, (state, action) => {
        if (action.error.message !== 'canceled') state.fetchReportOverviewStatus = 'rejected';
      })
      .addCase(fetchReportDriversThunk.pending, (state) => {
        state.fetchReportDriversStatus = 'pending';
      })
      .addCase(fetchReportDriversThunk.fulfilled, (state, action) => {
        state.reportDrivers = action.payload.data;
        state.fetchReportDriversStatus = 'fulfilled';
      })
      .addCase(fetchReportDriversThunk.rejected, (state, action) => {
        if (action.error.message !== 'canceled') state.fetchReportDriversStatus = 'rejected';
      });
  },
});

export const {
  resetEditFilterReduxState,
  updateReportSelectedSource,
  updatePreviousFilterRelation,
  updateDataSourceFilters,
  updateChartComparator,
  updateChartDimension,
  updatechartMetric,
  updateFilterDateRange,
  updateEditReportRestrictDimension,
  updateChartPreviousInterval,
  updateSelectedDateField,
  updateVisualizationType,
  updateChartIntervalBreakdown,
  updateTableVisualization,
  updateSelectedVisualizationType,
  updateDriverNumber,
  updateDriverShow,
  updateShowBarGraphWarning,
} = EditGraphReportSlice.actions;

export default EditGraphReportSlice.reducer;
